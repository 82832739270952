(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

angular.module('app').directive('validateUnselectedFares', validateUnselectedFaresController);

function validateUnselectedFaresController() {
  return {
    controller: ['$scope', '$document', validateFares]
  };
}

var validateFares = function validateFares($scope, $document) {
  var newBut = angular.element($document.find('.btn.btn_next'));
  newBut.on('click', function () {
    var priceCells = $document.querySelectorAll('a.price-cell__link');
    var priceCellsMobile = $document.querySelectorAll('div.chooseFlight__list__item');
    var priceCellsContentMobile = $document.querySelectorAll('a.chooseFlight__table__item__content__link');
    var firstTableCells = $document.querySelectorAll('.chooseFlight:nth-child(1) a.price-cell__link');
    var secondPriceCellsInputs = angular.element($document.find('flight_1'));
    var firstPriceCellsMobile = angular.element($document.querySelectorAll('div.chooseFlight__list')[0].querySelectorAll('div.chooseFlight__list__item'));
    var firstPriceCellsContentMobile = angular.element($document.querySelectorAll('div.chooseFlight__table')[0].querySelectorAll('a.chooseFlight__table__item__content__link'));
    priceCells.map(function (cell) {
      setBorderColorToElement(priceCells[cell], '#b01c2e');
      setBGColorToElement(priceCells[cell], '#b01d2e1c');
      isNoSelectedVariants() && scrollToElement(priceCells[0]);
    });
    $scope.vm.selectedVariants.length === 1 && firstTableCells.map(function (cell) {
      setBorderColorToElement(firstTableCells[cell], 'transparent');
      setBGColorToElement(firstTableCells[cell], 'transparent');
    });
    priceCells.on('click', function () {
      priceCells.map(function (cell) {
        setBorderColorToElement(priceCells[cell], 'transparent');
        setBGColorToElement(priceCells[cell], 'transparent');
      });
    }); // Mobile

    priceCellsMobile.map(function (mobCell) {
      setBorderColorToElement(priceCellsMobile[mobCell], '#b01c2e');
      isNoSelectedVariants() && scrollToElement(priceCellsMobile[0]);
      $scope.$watch('priceCellsContentMobile', function () {
        isNoSelectedVariants() && scrollToElement(priceCellsContentMobile[0]);
        priceCellsContentMobile.map(function (mobContentCell) {
          setBorderColorToElement(priceCellsContentMobile[mobContentCell], '#b01c2e');
          firstPriceCellsMobile.map(function (cell) {
            setBorderColorToElement(firstPriceCellsMobile[cell], '#e6e6e6');
          });
          secondPriceCellsInputs.map(function (inp) {
            secondPriceCellsInputs[inp].checked && isNoSelectedVariants() && scrollToElement(priceCellsMobile[0]);
          });
          $scope.vm.selectedVariants.length === 1 && firstPriceCellsContentMobile.map(function (cell) {
            setBorderColorToElement(firstPriceCellsContentMobile[cell], '#e6e6e6');
          }) && scrollToElement(priceCellsMobile[priceCellsMobile.length - 1]);
        });
      });
    });
  }); // Common functions

  function scrollToElement(element) {
    element.scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    });
  }

  function setBorderColorToElement(element, color) {
    element.style.border = "1px solid ".concat(color);
  }

  function setBGColorToElement(element, color) {
    element.style.background = "".concat(color);
  }

  function isNoSelectedVariants() {
    return $scope.vm.selectedVariants.length === 0;
  }
};

},{}],2:[function(require,module,exports){
"use strict";

require('./directives/validateUnselectedFares');

require('./services/googleAnalyticsTracker');

angular.module('app').run(['googleAnalyticsTracker', function (googleAnalyticsTracker) {
  googleAnalyticsTracker();
}]);

},{"./directives/validateUnselectedFares":1,"./services/googleAnalyticsTracker":3}],3:[function(require,module,exports){
"use strict";

angular.module('app').factory('googleAnalyticsTracker', ['watchman', '$interval', '$window', googleAnalyticsTracker]);

function googleAnalyticsTracker(watchman, $interval, $window) {
  var EVENTS = {},
      ORDER_EVENTS = {
    ORDER_BOOKED: 'Заказ создан',
    ORDER_CANCELLED: 'Заказ отменен',
    ORDER_PAYMENT_SUCCESSFULLY_FINISHED: 'Заказ успешно оплачен',
    ORDER_PAYMENT_FAILED: 'Неудачная оплата заказа'
  },
      ORDER_EVENT_HANDLERS = {
    ORDER_BOOKED: orderBooked,
    ORDER_CANCELLED: orderCancelled,
    ORDER_PAYMENT_SUCCESSFULLY_FINISHED: orderPaymentFinished,
    ORDER_PAYMENT_FAILED: orderPaymentFailed
  },
      GA_ID = 'GTM-56ZN8S';
  var ga;
  var ym;
  var yaInterval = $interval(function () {
    if ($window.yaCounter37055640) {
      ym = $window.yaCounter37055640;
      $interval.cancel(yaInterval);
    }
  });
  var gaInterval = $interval(function () {
    if ($window.ga) {
      ga = $window.ga;
      ga('create', GA_ID, 'auto');
      ga('require', 'ec');
      ga('send', 'pagevew');
      $interval.cancel(gaInterval);
    }
  }, 100, 20);
  return init;

  function init() {
    var gaInterval = $interval(function () {
      if ($window.ga) {
        watchman.addListener(overlooker);
        watchman.addOrderEventListener(orderOverlooker);
        $interval.cancel(gaInterval);
      }
    }, 100, 20);
    var yaInterval = $interval(function () {
      if ($window.yaCounter37055640) {
        ym = $window.yaCounter37055640;
        watchman.addListener(overlooker);
        watchman.addOrderEventListener(orderOverlooker);
        $interval.cancel(yaInterval);
      }
    }, 100, 20);
  }

  function overlooker(keyEvent) {// const handler = EVENTS_HANDLERS[`${keyEvent.name}__${keyEvent.type}`];
    // const event = EVENTS[keyEvent.name];
    // if (keyEvent.type === 'response' && event) {
    // 	// ga('send', 'event', event);
    // }
    //
    // if (handler) {
    // 	// handler(keyEvent.data);
    // }
  }

  function orderOverlooker(orderEvent, orderInfo) {
    var handler = ORDER_EVENT_HANDLERS[orderEvent.type];
    var event = ORDER_EVENTS[orderEvent.type];

    if (event) {
      ga('send', 'event', event);
    }

    if (handler) {
      handler(orderInfo);
    }
  }

  function orderCancelled(orderInfo) {
    ga('ec:setAction', 'checkout', {
      step: 1,
      option: orderInfo.paymentform
    });
    ym.reachGoal('orderCancelled');
    ga('send', 'pageview');
  }

  function orderBooked(orderInfo) {
    var esProducts = getExtraServicesAsProducts(orderInfo);
    var flightsProducts = getFlightsAsProducts(orderInfo);
    var productOrder = getOrderInfoAsProduct(orderInfo);
    ym.reachGoal('orderBooked');
    esProducts.map(function (es) {
      return ga('ec:addProduct', es);
    });
    flightsProducts.map(function (flightProduct) {
      return ga('ec:addProduct', flightProduct);
    });
    ga('ec:addProduct', productOrder);
    ga('ec:setAction', 'checkout', {
      step: 1,
      option: orderInfo.paymentform
    });
    ga('send', 'pageview');
  }

  function orderPaymentFailed(orderInfo) {
    ga('send', 'event', 'Ecommerce', 'Payment Failed', {
      paymentForm: orderInfo.paymentForm
    });
    ym.reachGoal('orderPaymentFailed');
  }

  function orderPaymentFinished(orderInfo) {
    var esProducts = getExtraServicesAsProducts(orderInfo);
    var flightsProducts = getFlightsAsProducts(orderInfo);
    var productOrder = getOrderInfoAsProduct(orderInfo);
    ym.reachGoal('orderPaymentFinished');
    esProducts.map(function (es) {
      return ga('ec:setAction', 'purchase', es);
    });
    flightsProducts.map(function (flightProduct) {
      return ga('ec:setAction', 'purchase', flightProduct);
    });
    ga('ec:setAction', 'purchase', productOrder);
    ga('send', 'pageview');
  }

  function getExtraServicesAsProducts(orderInfo) {
    var extraServices = [];

    if (_.has(orderInfo, 'editableExtraServicesHeap')) {
      orderInfo.editableExtraServicesHeap.forEach(function (es) {
        extraServices.push({
          id: es.rfisc,
          name: es.title,
          category: es.groupCode,
          variant: es.subgroupCode,
          price: es.totalPrice,
          quantity: es.amount
        });
      });
    }

    return extraServices;
  }

  function getFlightsAsProducts(orderInfo) {
    var flights = [];
    orderInfo.flights.forEach(function (segment) {
      segment.flights.forEach(function (flight) {
        flights.push({
          id: flight.id,
          name: "flight ".concat(flight.origincity, " - ").concat(flight.destinationcity),
          category: "vehicle: ".concat(flight.airplaneName),
          brand: flight.brand,
          variant: "".concat(flight.flightClass),
          quantity: 1
        });
      });
    });
    return flights;
  }

  function getOrderInfoAsProduct(orderInfo) {
    return {
      id: orderInfo.header.regnum,
      name: orderInfo.brand,
      category: 'order',
      brand: orderInfo.brand,
      price: orderInfo.amountToPay,
      quantity: 1
    };
  }
}

},{}]},{},[2]);
